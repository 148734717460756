<template>
  <table class="alerts-table" v-show="showAlertTableComputed">
    <thead>
        <th v-for="header in getHeadersType[dsp]" v-bind:key="header.label" :class="header.class">
            <v-row v-if="header.img !== null">
              <v-col
                offset-xs="4"
                offset-sm="4"
                offset-md="4"
                cols="4"
                class="pa-1"
              >
                <v-img
                    :src="require('../../../assets/' + $safeGet(groupKey, header.img).toLowerCase() + '.png')"
                    aspect-ratio="1"
                    heigth="50%"
                    width="50%"
                    class="dsp-img-alerts styled-shadow-hover"
                />
              </v-col>
            </v-row>
            <v-icon v-if="header.icon !== null">{{header.icon}}</v-icon>

            <div v-if="header.label ==='AM : '">
              <UsersInfo
                :users="$safeGet(groupKey, header.value)"
              >
              </UsersInfo>
            </div>

            <div v-else-if="header.class === 'io-col'">
              <CopyClipboardComponent
                :to-copy="copyIOorBulkAddID()"
                :icon-small="true"
                :tooltip-text="`Copy ${$safeGet(groupKey, 'group_key.entity_type') === 'BULK_ADD' ? 'BulkAdd' : 'IO'} ID to clipboard`"
                :elevation="false"
                icon-color="gray"
              >

              </CopyClipboardComponent>
              <a :href="generateHeaderLink(groupKey, dsp, header.value)" target="_blank" rel="noopener noreferrer">
                <span v-if="header.label !== null"> {{header.label}} </span>
                <span v-if="header.value !== null"> {{$safeGet(groupKey, header.value)}}</span>
                <v-tooltip bottom v-if="header.value !== null">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{getName(groupKey, header.value, maxLengthNameHeader)}}</span>
                  </template>
                  <span>{{getName(groupKey, header.value)}}</span>
                </v-tooltip>
              </a>
            </div>

            <div v-else>
              <template v-if="header.label != null && header.value != null">
                <span v-if="$safeGet(groupKey, header.value) === 'NONE'">
                  Multiple {{header.label.toLowerCase()}}s
                </span>
                <span v-else>
                  {{header.label}} {{$safeGet(groupKey, header.value)}}
                </span>
              </template>
              <v-tooltip bottom v-if="header.value !== null">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{getName(groupKey, header.value, maxLengthNameHeader)}}</span>
                </template>
                <span>{{getName(groupKey, header.value)}}</span>
              </v-tooltip>
            </div>
        </th>
    </thead>
    <tbody>
        <template v-for="(item, errorType) in alertsFilteredStatusSorted">
            <tr
                @mouseenter="$set(isHover, groupKey.key + errorType, true)"
                @mouseleave="$set(isHover, groupKey.key + errorType, false)"
                class="gmail-like alert-line"
                :class="{'done-or-false-positive': groupKey.isErrorTypeDone(errorType) || groupKey.isErrorTypeFalsePositive(errorType) }"
                :id="item[0].id"
                v-bind:key="errorType"
            >
                <td class="checkbox-alert-bucket">
                  <v-checkbox
                    v-model="bucketsBoxChecked[errorType]"
                    @change="manageCheckBox(errorType)"
                  >
                  </v-checkbox>
                </td>
                <td
                @click="$emit('select-alert', item)"
                :style="'background: ' + $getInfoError(errorType).color"
                :class="$getInfoError(errorType).category === 'real time alerts' && (activateBlink || isUserWantBlink()) ? 'flashit cursor-pointer textAlignCenter' : 'cursor-pointer textAlignCenter'"
                >
                  <span class="number-alert">{{groupKey.countAlerts[errorType]}}</span>
                </td>

                <td
                @click="$emit('select-alert', item)"
                class="cursor-pointer textAlignCenter"
                colspan="2"
                >
                    {{groupKey.alerts[errorType][0].dateToLocal()}}
                </td>

                <td
                :style="'color: ' + $getInfoError(errorType).color"
                @click="$emit('select-alert', item)"
                class="cursor-pointer"
                >
                    <v-row
                        no-gutters
                        class=""
                    >
                      <span>
                        {{errorType}} {{$getInfoErrorType(errorType)}}
                      </span>
                    </v-row>

                </td>

                <td
                  @click="$emit('select-alert', item)"
                  class="cursor-pointer textAlignCenter"
                >
                  <div class="alert-main-data elevation-1" v-html="getMainDataTemplate(item[0])"></div>
                </td>

                <!-- ICON INFOS -->
                <td class="textAlignRight icon-info-td">

                  <v-row class="cursor-pointer" @click="$emit('select-alert', item)">
                    <v-col cols="9" class="status-info-container">
                      <span v-if="groupKey.alerts[errorType][0].latest_snooze !== null" class="textAlignCenter red--text">
                        SNOOZED {{$transformIsoDate(groupKey.alerts[errorType][0].latest_snooze)}}
                      </span>
                      <span
                        v-for="(action, index) in getStatusAction"
                        v-bind:key="index + 'icon'"
                      >
                        <span v-if="action !== undefined && groupKey.isErrorType(action.action, errorType)">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon @mouseenter="loadToolTipTextAction(action, item)" v-on="on" :class="'info-status-icon' + ' color-' + action.icon">{{action.icon}}</v-icon>
                            </template>
                            <span>{{ getToolTipTextAction(action, item) }}</span>
                          </v-tooltip>
                        </span>
                      </span>
                    </v-col>

                    <v-col cols="3">
                      <v-menu bottom left >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            v-on="on"
                            @click="$emit('select-alert', item)"
                          >
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>
                        <div class="menu-alerts">
                          <v-list>

                            <!-- DONE-->
                            <v-list-item
                              @click="actionIsEnabled(item, action.action) ? emitUpdateAndSelectLine(action.action, item) : ''"
                              v-for="(action, index) in getStatusAction"
                              v-bind:key="index"
                              :class="(action !== undefined && !actionIsEnabled(item, action.action) ? 'alert-is-class' : 'cursor-pointer')"
                            >
                               <v-icon v-if="action !== undefined && actionIsEnabled(item, action.action)">{{action.icon}}</v-icon>
                               <v-list-item-title class="text-menu-alert" v-if="action !== undefined && actionIsEnabled(item, action.action)">
                                 {{
                                 groupKey.isErrorType(action.action, errorType)
                                 ? typeof action.done_text === 'function' ? action.done_text(item) : action.done_text
                                 : action.text
                                 }}
                               </v-list-item-title>
                            </v-list-item>
                            <!-- CANCEL -->
                            <v-list-item v-if="displayCancelBtn(item)" @click="emitOpenDialogCancel(item)">
                              <v-icon>
                                cancel
                              </v-icon>
                              <v-list-item-title class="text-menu-alert">
                                {{getLabelCancel(item)}}
                              </v-list-item-title>
                            </v-list-item>

                            <!-- SNOOZE -->
                            <v-list-item v-if="displaySnoozeBtn(item)" @click="emitOpenDialogSnooze(item)">
                              <v-icon>
                                notifications
                              </v-icon>
                              <v-list-item-title class="text-menu-alert">
                                Remind me later
                              </v-list-item-title>
                            </v-list-item>

                            <!-- REMOVE-->
                            <v-list-item
                              @click="displayRemoveButton(groupKey, action, errorType) ? emitRemoveAndSelectLine(action.action, item, groupKey) : ''"
                              v-for="(action, index) in getStatusAction"
                              v-bind:key="index + '_remove'"
                              :class="!displayRemoveButton(groupKey, action, errorType) ? 'alert-is-class' : 'cursor-pointer'"
                            >
                              <v-icon v-if="displayRemoveButton(groupKey, action, errorType)">{{action.icon}}</v-icon>
                              <v-list-item-title class="text-menu-alert" v-if="displayRemoveButton(groupKey, action, errorType)">{{action.remove_text}}</v-list-item-title>
                            </v-list-item>

                            <!-- AI PERFORMANCE -->
                            <template v-if="displayAiPerformanceButtons(item)">
                              <v-list-item
                                @click="processAiPerformance(aiPerf, item)"
                                class="cursor-pointer"
                                v-for="(aiPerf, aiPerfIndex) in aiPerformanceItems"
                                v-bind:key="aiPerfIndex + '_ai_perf'"
                              >
                                <v-icon>
                                  {{ aiPerf.icon }}
                                </v-icon>
                                <v-list-item-title class="text-menu-alert text-menu-ai-perf">
                                  {{ aiPerf.text }}
                                </v-list-item-title>
                              </v-list-item>
                            </template>
                          </v-list>
                        </div>
                      </v-menu>
                    </v-col>

                  </v-row>
                </td>
            </tr>
        </template>
    </tbody>
</table>
</template>
<script>
import { mapGetters } from 'vuex'
import UsersInfo from './TableFragments/UsersInfo'
import alertModelHelper from '../../../models/Helpers/alertModelHelper'
import GroupKeyModel from '@/models/Alertings/GroupKeyModel'
import { LoadTooltipTextMixin } from '@/mixins/loadTooltipTextMixin'
import { getAlertsTableHeaders } from './TableFragments/AlertsTableHeadersFile'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent'

export default {
  name: 'AlertsTable',
  props: {
    groupKey: {
      type: [GroupKeyModel, Object],
      default: function () {
        return {}
      }
    },
    dsp: {
      type: String
    }
  },
  mixins: [
    LoadTooltipTextMixin
  ],
  components: {
    UsersInfo,
    CopyClipboardComponent
  },
  data: function () {
    return {
      headers: getAlertsTableHeaders(),
      items: [],
      isHover: {},
      activateBlink: false,
      /**
       * fill this array with the user who want her red alert to blink
       */
      userWantBlink: [],
      bucketsBoxChecked: {},
      /**
       * contain the added id by errorType for the buckets for removing them if necessary
       */
      addedIdBuckets: {},
      tooltipText: {},
      maxLengthNameHeader: 18,
      cancellableAlertType: ['4.1.1', '4.1.2', '4.1.3', '4.2.1', '4.2.2', '4.2.3', '2.1.5', '4.3.1', '4.2.6'],
      excludeIoFromAutoplugAlertType: ['4.2.1', '4.2.6'],
      snoozableAlerts: ['1.2.1', '1.3.1', '1.3.2', '1.3.3', '1.3.4', '2.1.1', '2.1.3', '2.1.4', '2.2.1', '2.2.2', '2.2.3', '2.2.4', '2.2.5', '2.3.2', '2.3.4', '2.4.1', '2.5.2'],
      alertBrief: ['4.1.1', '4.2.1', '4.3.1'],
      aiPerformanceAlerts: ['5.1.1'],
      aiPerformanceItems: [
        {
          text: 'We clearly performed better',
          value: 'better',
          icon: 'chevron_right'
        },
        {
          text: 'We had mixed results',
          value: 'mixed',
          icon: 'chevron_right'
        },
        {
          text: 'We clearly did not performed better',
          value: 'poorer',
          icon: 'chevron_right'
        },
        {
          text: 'Test not ended, remind me in two weeks',
          value: 'to_be_defined',
          icon: 'chevron_right'
        }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
    emitOpenDialogCancel (item) {
      return this.$emit('dialog-cancel', item)
    },
    displayCancelBtn (item) {
      return item.length > 0 && this.cancellableAlertType.includes(item[0].error_type)
    },
    getLabelCancel (item) {
      const defaultLabel = 'Cancel request'
      if (!item.length) {
        return defaultLabel
      }
      const errorType = item[0].error_type

      if (this.excludeIoFromAutoplugAlertType.includes(errorType)) {
        return 'Exclude IO from Autoplug'
      }
      return defaultLabel
    },
    displayAiPerformanceButtons (item) {
      return item.length > 0 && this.aiPerformanceAlerts.includes(item[0].error_type)
    },
    displaySnoozeBtn (item) {
      return item.length > 0 && this.snoozableAlerts.includes(item[0].error_type)
    },
    emitOpenDialogSnooze (item) {
      return this.$emit('dialog-snooze', item)
    },
    emitUpdateAndSelectLine: function (action, item) {
      this.$emit('update', action, item)
      this.$emit('select-alert', item)
    },
    emitRemoveAndSelectLine (action, item) {
      this.$emit('remove', action, item, true)
      this.$emit('select-alert', item)
    },
    actionIsEnabled (item, action) {
      let errorType = item[0].error_type

      if (this.aiPerformanceAlerts.includes(errorType)) {
        return false
      }

      if (action === 'attributed') {
        return (this.groupKey.isErrorTypeFalsePositive(errorType) || this.groupKey.isErrorTypeHelp(errorType)) && !this.groupKey.isErrorType(action, errorType)
      } else if (action === 'fixed') {
        return this.groupKey.isErrorTypeFalsePositive(errorType) && !this.groupKey.isErrorTypeHelp(errorType) && !this.groupKey.isErrorType(action, errorType)
      } else if (action === 'true_positive') {
        return this.groupKey.isErrorTypeFalsePositive(errorType) && !this.groupKey.isErrorType(action, errorType)
      } else if (action === 'false_positive') {
        return this.cancellableAlertType.indexOf(errorType) === -1 && !this.groupKey.isErrorTypeDone(errorType) && !this.groupKey.isErrorTypeFalsePositive(errorType) && !this.groupKey.isErrorTypeTruePositive(errorType)
      }
      return (!this.groupKey.isErrorTypeDone(errorType) && !this.groupKey.isErrorTypeFalsePositive(errorType) && !this.groupKey.isErrorType(action, errorType))
    },
    /**
     * @param alert {AlertModel}
     * @returns {string}
     */
    getMainDataTemplate (alert) {
      let template = (this.$getInfoError(alert.error_type)).mainData

      if (template === undefined) {
        return 'NC'
      }
      return template(alert.data, alert.settings, alert.group_key)
    },
    isUserWantBlink () {
      let currentUser = this.$store.getters.getCurrentUser

      return this.userWantBlink.indexOf(currentUser.toLowerCase()) !== -1
    },
    async addIdToBucketsList (errorType) {
      let groupKeyId = this.groupKey.alerts[errorType][0].id
      const idList = [groupKeyId]
      this.addedIdBuckets[errorType] = idList
      this.$store.commit('addIdsToBucket', idList)
    },
    removeIdFromBucketsList (errorType) {
      let idList = this.addedIdBuckets[errorType]
      this.$store.commit('removeIdsFromBucket', idList)
    },
    manageCheckBox (errorType) {
      if (this.bucketsBoxChecked[errorType]) {
        this.addIdToBucketsList(errorType)
      } else {
        this.removeIdFromBucketsList(errorType)
      }
    },
    resetBucketsButton () {
      this.bucketsBoxChecked = {}
    },
    /**
     * @param groupKey {GroupKeyModel}
     * @param action {string}
     * @param errorType {string}
     */
    displayRemoveButton (groupKey, action, errorType) {
      return action !== undefined && groupKey.isErrorType(action.action, errorType) && action.action !== 'false_positive'
    },
    /**
     * @param groupKey {GroupKeyModel}
     * @param dsp {string}
     * @param headerValue {string}
     */
    generateHeaderLink (groupKey, dsp, headerValue) {
      // TODO SLY -> new link to surcouche if no headerValue
      let errorTypeBrief = null
      this.alertBrief.forEach((errorType) => {
        if (groupKey.haveGroupKeyAlertsOfType(errorType)) {
          errorTypeBrief = errorType
        }
      })
      if (errorTypeBrief) {
        return alertModelHelper.generateDataFormLinkToSurcouche(groupKey.alerts[errorTypeBrief][0], this.$_FRONT_HOST)
      }
      return this.$generateLinkToOverlay(this.$safeGet(groupKey, headerValue), dsp)
    },
    /**
     * @param groupKey {GroupKeyModel}
     * @param headerValue {string}
     * @param maxLength? {number}
     */
    getName (groupKey, headerValue, maxLength = null) {
      const optSubStr = (val, len) => typeof len === 'number' && val.length > len ? `${val.substring(0, len)}...` : val
      const strTemp = (val, len = null) => ` (${optSubStr(val, len)})`
      if (headerValue === 'group_key.client_id' && groupKey.group_key.member_name) {
        return strTemp(groupKey.group_key.member_name, maxLength)
      } else if (headerValue === 'group_key.insertion_order_id' && groupKey.group_key.io_name) {
        return strTemp(groupKey.group_key.io_name, maxLength)
      } else if (headerValue === 'group_key.advertiser_id' && groupKey.group_key.advertiser_name) {
        return strTemp(groupKey.group_key.advertiser_name, maxLength)
      } else {
        return ''
      }
    },
    copyIOorBulkAddID () {
      return (
        this.$safeGet(this.groupKey, 'group_key.entity_type') === 'BULK_ADD'
          ? this.$safeGet(this.groupKey, 'group_key.entity_id')
          : this.$safeGet(this.groupKey, 'group_key.insertion_order_id')
      )
    },
    /**
     * @param aiPerf {{text: string, value: AiPerformance}}
     * @param alerts {AlertModel[]}
     */
    async processAiPerformance (aiPerf, alerts) {
      if (!alerts.length) {
        console.warn('no alert to process')
        return
      }
      let data = {
        ai_performance: aiPerf.value
      }

      if (aiPerf.value === 'to_be_defined') {
        // Date object equal to today + 15 days
        data.ab_test_end_date = new Date(new Date().setDate(new Date().getDate() + 15))
      }

      const overviewId = alerts[0].data.overview_id
      const response = await this.$apiCaller.putBaseline(overviewId, data)

      if (this.$apiCaller.isResponseError(response)) {
        const status = response.response ? response.response.status : 500

        if (status === 403) {
          this.$emit('error', 'You are not allowed to change the AI performance')
        } else {
          this.$emit('error', 'An error occurred while changing the AI performance')
        }
      } else {
        console.warn('success')
        const alert = alerts[0]
        this.$emit('success', 'Success!', alert)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getStatusAction'
    ]),
    showAlertTableComputed: function () {
      return Object.keys(this.alertsFilteredStatusSorted).length > 0
    },
    allAlerts: function () {
      return this.groupKey.alerts
    },
    alertsFilteredStatus: function () {
      return this.allAlerts
    },
    /**
     * sort alert first a first group composed per not done and not false_positive alert
     * and another group with done or false_positive alert
     * the 2 group are sorted per errorType ( this work is done in the back end )
     * @return { Record<string, AlertModel[]> }
     */
    alertsFilteredStatusSorted: function () {
      const doneOrFalsePositiveLast = (a, b) => {
        let pointsA = 0
        let pointsB = 0
        let usedAlerts = this.alertsFilteredStatus

        if (usedAlerts[a][0].isDone()) {
          pointsA += 1
        }

        if (usedAlerts[a][0].isFalsePositive()) {
          pointsA += 1
        }

        if (usedAlerts[b][0].isDone()) {
          pointsB += 1
        }
        if (usedAlerts[b][0].isFalsePositive()) {
          pointsB += 1
        }

        return pointsA - pointsB
      }

      let keys = Object.keys(this.alertsFilteredStatus).sort(doneOrFalsePositiveLast)

      let sortedAlerts = {}

      for (let i in keys) {
        let errorType = keys[i]
        /**
         * debugger autocomplete case
         */
        sortedAlerts[errorType] = this.alertsFilteredStatus[errorType]
      }
      return sortedAlerts
    },
    getHeadersType () {
      return this.headers[this.groupKey.group_key.insertion_order_id !== 'NONE' ? 'brief' : 'bulkadd']
    }
  },
  watch: {}
}
</script>

<style>

/* Flash class and keyframe animation */

/* eslint-disable no-tabs */
.flashit{
    -webkit-animation: flash linear 1s infinite;
    animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
    0% { opacity: 1; }
    50% { opacity: .1; }
    100% { opacity: 1; }
}
@keyframes flash {
    0% { opacity: 1; }
    50% { opacity: .1; }
    100% { opacity: 1; }
}

/* eslint-enable no-tabs */

.alerts-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.3rem;
    background-color: #FAFAFA;
    border-collapse: collapse;
    font-size: 10px;
    border-radius: 2px;
}

.alerts-table > thead > tr, .alerts-table > tbody > tr, .table > tfoot > tr {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.alerts-table tbody tr:hover {
    /*background-color: lightgray;*/
}

.alerts-table tbody {
  background-color: white;
}

.alerts-table thead {
  background-color: #FAFAFA;
  font-weight: 500;
  color: rgba(0,0,0,0.54);
}

.alerts-table-expand {
  height: 5em;
  border-top: 1px solid lightgray;
}

.transparent-td {
  background: whitesmoke;
}

.dsp-img-alerts {
  padding: auto;
}

.textAlignRight {
    text-align: right;
}

.textAlignCenter {
    text-align: center;
}

table.alerts-table td {
    height: 4em;
}
table.alerts-table th {
    height: 4em;
    padding: 1em;
}

table.alerts-table th:hover {
    color: gray;
}

.menu-alerts {
  background-color: white;
}

.am-col {
    width: 7em;
}

.dsp-col {
    width: 12em;
}

.member-col {
    width: 17em;
}

.ad-col {
    width: 25em;
}

.io-col {
    width: 19em;
}

.color-col {
    width: 8em;
}

.done-or-false-positive {
  background: lightyellow;
}

.tools-alert-table {

}

.text-menu-alert {
  padding-left: 0.5em;
}

.text-menu-ai-perf {
  font-size: 14px;
}
.number-alert {
  font-weight: bold;
}

.alert-line {
  transition: 0.1s;
}

.alert-line:hover {
  background-color: lightgrey;
}

.alert-is-class {
  background: lightgray;
  color: dimgrey;
  display: none;
}

.alert-is-class a {
  cursor: default;
}

.status-info-container {
  /*margin: 1.66em;*/
  margin: auto;
  padding: 0.3em;
}

.info-status-icon {
  font-size: 20px;
  padding: 0.4em;
}

.info-status-icon.color-close {
  color: #C51230 !important;
}

.info-status-icon.color-check {
  color: #5BB974 !important;
}

.info-status-icon.color-help_outline {
  color: #199AD6 !important;
}

.info-status-icon.color-replay {
  color: orange !important;
}

.alert-main-data {
  border-radius: 5px;
  padding: 5px;
  background: #f4efef;
  font-weight: 500;
  transition: 0.3s;
  color: black;
}

.alert-main-data:hover {
  background: white;
  color: black;
}

.alert-main-data strong {
  color: darkred;
}

.no-display {
  display: none;
}

.checkbox-col {
  width: 3em;
}

.checkbox-alert-bucket .v-input.v-input--selection-controls.v-input--checkbox.theme--light {
  margin: 0;
  padding-top: 0;
  padding-bottom: 1.6em;
  padding-left: 0.6em;
  height: 0.8em;
}

.icon-info-td {
  padding-right: 0.5em;
}
</style>
